// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
Rails.start();

require("turbolinks").start()
require("@rails/activestorage").start()
require("./channels")

require('clipboard')


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "trix"
import "@rails/actiontext"

require("./ui")
require("./utilities")

// require("@fortawesome/fontawesome-pro/js/all.js")
// require("../../vendor/fontawesome-subset/js/all.js")
require("../../vendor/fontawesome-subset/js/fontawesome.js")
require("../../vendor/fontawesome-subset/js/light.js")
require("../../vendor/fontawesome-subset/js/duotone.js")
require("../../vendor/fontawesome-subset/js/brands.js")
FontAwesome.config.mutateApproach = 'sync'
